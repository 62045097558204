/*
This file was generated by the Surface compiler.
*/

/* Gateway.Components.Atoms.Autocomplete (balhb) */

.wrapper[s-balhb] {
  display: flex;
  flex-direction: column;
  position: relative;
}

.container[s-balhb] {
  word-break: break-all;
  border-radius: 6px;
  box-shadow: 0 1px 6px 0 #00000033;
  background-color: white;
  overflow-y: auto;
  padding: 16px 0;
}
.option[s-balhb] {
  min-height: 40px;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  display: flex;
  position: relative;
}
.option[s-balhb] > option[s-balhb] {
  padding: 10px 10px 10px 40px;
  width: 100%;
}

.option[s-balhb]:hover {
  background-color: #e6e6e6;
}

.option[s-balhb] > .icon {
  flex-shrink: 0;
  padding: 3px;
  border-radius: 50%;
  transition: 0.3s background-color;
  position: relative;
  color: black;
  border: 1px dashed black;
}
.option[s-balhb] > .icon {
  position: absolute;
  pointer-events: none;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.option[s-balhb].selected[s-balhb] > .icon {
  color: #deffe9;
  border: 1px solid #2fd566;
  background-color: #2fd566;
  position: absolute;
}

.selection-item[s-balhb] {
  background-color: #e9efff;
  color: #455bf3;
  border-radius: 6px;
  padding: 1px 6px;
  font-size: 0.88rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* Gateway.Components.Atoms.Avatar (ieuak) */

.avatar[s-ieuak] {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  text-transform: uppercase;
  line-height: 0;
  user-select: none;
  color: var(--77h7i);
  width: var(--5mzz7);
  height: var(--5mzz7);
  background-color: var(--fhq36);
  transition: box-shadow 0.08s ease-in-out;
}

.avatar[s-ieuak]:hover {
  box-shadow: 0 0 0 2px var(--fhq36);
}

.selected[s-ieuak] {
  transition: initial;
  box-shadow: 0 0 0 4px #00a8ff !important;
}

.avatar[s-ieuak] > .badge[s-ieuak] {
  position: absolute;

  right: -8px;
  top: -8px;

  width: 22px;
  height: 22px;
  font-size: 0.6em;
  font-weight: 500;

  border-radius: 50%;
  color: white;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25em;
}

/* Gateway.Components.Atoms.Button (s5be7) */

.base-button[s-s5be7] {
  font-weight: 600;
  position: relative;
  -webkit-user-select: none;
  user-select: none;

  justify-content: center;
  text-align: center;

  background: transparent;
  white-space: nowrap;

  background-color: white;
  border: 1px solid transparent;
  line-height: 1.25;
  font-family: "Open Sans", sans-serif;
  transition: box-shadow 0.1s, background 0.05s;

  border-radius: 6px;
  padding: 0.45em 0.8em;

  display: flex;
  align-items: center;
  gap: 0.5em;
}

.base-button[s-s5be7]:disabled {
  color: #5c5f76;
  background-color: #d5d9e4;
  cursor: initial;
  box-shadow: initial;
}

.base-button[s-s5be7]:active:not(:disabled) {
  transform: translateY(1px);
}

.small[s-s5be7] {
  font-size: 0.8em;
  border-radius: 4px;
  padding: 0.25em 0.6em;
}

.primary[s-s5be7] {
  color: #fafbff;
  background-color: #455bf3;
  box-shadow: 0 0 0 1px #3c51da, 0 2px 5px 0 rgba(50, 50, 93, 0.08),
    0 1px 1.5px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(111, 128, 236, 0.5);
}
.primary[s-s5be7]:is(:hover, :focus):not(:disabled) {
  background-color: #3145c9;
}
.primary[s-s5be7]:active:not(:disabled) {
  background-color: #223195;
}

.secondary[s-s5be7] {
  color: #17171b;
  box-shadow: 0 0 0 1px rgba(50, 50, 93, 0.1),
    0 2px 5px 0 rgba(50, 50, 93, 0.08), 0 1px 1.5px 0 rgba(0, 0, 0, 0.07),
    0 1px 2px 0 rgba(0, 0, 0, 0.08);

  background-color: white;
}
.secondary[s-s5be7]:is(:hover, :focus):not(:disabled) {
  background-color: #e6e6e6;
}

.secondary[s-s5be7]:active:not(:disabled) {
  background-color: #d5d5d5;
}

.error[s-s5be7] {
  color: #ff3d10;
  background-color: #ffeae4;
  box-shadow: 0 0 0 1px rgb(247 219 211), 0 2px 5px 0 rgba(50, 50, 93, 0.08),
    0 1px 1.5px 0 rgba(0, 0, 0, 0.07);
}
.error[s-s5be7]:is(:hover, :focus):not(:disabled) {
  background-color: #ffd8d0;
}
.error[s-s5be7]:active:not(:disabled) {
  background-color: #ffbea9;
}

.success[s-s5be7] {
  color: white;
  box-shadow: 0 0 0 1px #32c53e, 0 2px 5px 0 rgba(50, 50, 93, 0.08),
    0 1px 1.5px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgb(76 162 79 / 50%);

  background-color: #2fd566;
}
.success[s-s5be7]:is(:hover, :focus):not(:disabled) {
  background-color: #2abf5e;
}
.success[s-s5be7]:active:not(:disabled) {
  background-color: #1f9e4e;
}

.secondary_active[s-s5be7] {
  color: #455bf3;
  background-color: #f3f6fd;
  box-shadow: 0 0 0 1px #b6c1ff, 0 2px 5px 0 rgb(32 32 185 / 8%);
}
.secondary_active[s-s5be7]:is(:hover, :focus):not(:disabled) {
  background-color: #e6eefc;
}
.secondary_active[s-s5be7]:active:not(:disabled) {
  background-color: #d5e0fb;
}

.transparent[s-s5be7] {
  color: #17171b;
  background-color: transparent;
  box-shadow: none;
}
.transparent[s-s5be7]:is(:hover, :focus):not(:disabled) {
  background-color: #e6e6e6;
}
.transparent[s-s5be7]:active:not(:disabled) {
  background-color: #d5d5d5;
}

.button[s-s5be7] > .icon {
  width: 1em;
  height: 1em;
}

/* Gateway.Components.Atoms.Chip (pzauq) */

.chip[s-pzauq] {
  border-radius: 4px;
  padding: 3px 6px;
  font-size: 0.88em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 1px;
  user-select: none;
  text-transform: uppercase;

  font-weight: 500;
}

.primary[s-pzauq] {
  color: var(--colorBlue50);
  background-color: var(--colorBlue10);
}

.secondary[s-pzauq] {
  color: var(--colorDark75);
  background-color: var(--colorDark15);
}

.error[s-pzauq] {
  color: var(--colorRed50);
  background-color: var(--colorRed10);
}

.success[s-pzauq] {
  color: var(--colorGreen75);
  background-color: var(--colorGreen10);
}

.warning[s-pzauq] {
  color: var(--colorOrange50);
  background-color: var(--colorOrange10);
}

.clickable[s-pzauq] {
  cursor: pointer;
}

.clickable[s-pzauq]:hover {
  filter: brightness(95%);
}

.clickable[s-pzauq]:active {
  filter: brightness(85%);
}

/* Gateway.Components.Atoms.ContentBlock (z42qk) */

.header[s-z42qk] {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 7px 16px;
}

.options[s-z42qk] {
  display: grid;
  gap: 10px;
  align-items: center;
  margin-left: auto;
  grid-auto-flow: column;
}

.title-icon[s-z42qk] {
  font-size: 1.1em;
  line-height: 0;
  padding-right: 0.2em;
}

.title[s-z42qk] {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  line-height: 1;
  padding: 7px 0;
}

.content-block[s-z42qk] {
  position: relative;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 7px 14px 0 rgb(50 50 93 / 2%), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
}

.body[s-z42qk]::-webkit-scrollbar {
  width: 8px;
}
.body[s-z42qk]::-webkit-scrollbar-thumb {
  background-color: #cccccc;
}

.body[s-z42qk] {
  position: relative;
  padding: 20px 17px;
}

.body[s-z42qk]:before {
  display: var(--n65j4);

  position: absolute;
  width: 98%;
  bottom: 0;
  height: 1px;
  background-color: #e8ebf5;
  content: "";
  left: 1%;
}

.status[s-z42qk] {
  display: flex;
  align-items: center;
  font-style: italic;
  color: #7a7f8c;
}

.wrapper[s-z42qk] {
  display: flex;
  gap: 16px;
}

/* Gateway.Components.Atoms.Dropdown.Item (n6suu) */

.dropdown-item[s-n6suu] {
  cursor: pointer;

  background: transparent;

  background-color: white;
  border: 1px solid transparent;
  font-family: "Open Sans", sans-serif;
  transition: box-shadow 0.1s, background 0.05s;

  border-radius: 6px;
  padding: 0.45em 0.8em;

  display: flex;
  justify-content: space-between;
  gap: 0.5em;
  color: var(--colorBase);
  width: 100%;
}

.dropdown-item[s-n6suu]:hover {
    color: var(--colorBlue75);
    background-color: var(--colorBlue10);
}

.dropdown-item__trash[s-n6suu] {
  color: var(--colorRed75);
}

.dropdown-item__trash[s-n6suu]:hover {
  color: var(--colorRed75);
  background-color: var(--colorRed10);
}

/* Gateway.Components.Atoms.FeedbackBlock (fypzv) */

.feedback-block[s-fypzv]{
  display: block;
  margin: 1em 0 2em 0;
  padding: 0.9em 1.45em;
  border-radius: 6px;
  font-weight: 600;
}

.error[s-fypzv] {
  background-color: var(--colorRed10);
  color: var(--colorRed50);
}

.success[s-fypzv] {
  background-color: var(--colorGreen10);
  color: var(--colorGreen75);
}


.info[s-fypzv] {
  background-color: var(--colorBlue10);
  color: var(--colorBlue75);
}

.spacer[s-fypzv]::before {
  content: "";
  display: block;
  height: 1.02em;
}
/* Gateway.Components.Atoms.Field (2rnwh) */

.label[s-2rnwh] {
  margin-bottom: 0.5em;
  font-weight: 600;
  color: #17171b;
  -webkit-font-smoothing: antialiased;
}

.required[s-2rnwh] {
  margin-left: 0.1em;
  vertical-align: top;
  display: inline;
  font-weight: 400;
  color: var(--colorRed50);
  font-size: var(--fontSizeSmall);
}

.help[s-2rnwh] {
  margin: 0.8em 0.4em 0.4em 0.4em;
  font-size: 0.88rem;
  color: var(--colorDark50);
}

/* Gateway.Components.Atoms.Header (xqfva) */

.menu[s-xqfva] {
  position: fixed;
  background-color: white;
  border-bottom: 1px solid #e5e5e5;
  top: 0;
  left: 0;
  right: 0;
  z-index: 50;
  height: 60px;
}

.menu-container[s-xqfva] {
  position: relative;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  margin: auto;
  padding: 0px 20px;
}

.logo[s-xqfva] {
  width: 40px;
  background-size: cover;
  height: 23px;
  cursor: pointer;
  background-position: center;
}

.actions[s-xqfva] {
  margin-left: auto;
}

.actions[s-xqfva] > a {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: var(--fontSizeMedium);
  text-decoration: none;
  padding: 12px;
}

/* Gateway.Components.Atoms.Modal (pwhqf) */

.modal[s-pwhqf] {
  display: none;
  z-index: 1000;
  inset: 0;
  overflow-y: auto;
  position: fixed;
}

.modal[s-pwhqf].is-active[s-pwhqf] {
  display: initial;
}
.modal-wrapper[s-pwhqf] {
  background-color: rgba(0, 0, 0, 0.35);

  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  min-height: 100%;
  position: relative;
  display: flex;
}

.modal-content[s-pwhqf] {
  z-index: 2;
  padding: 1.5rem;
  background-color: white;
  border: none;
  border-radius: 8px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3), 0 3px 7px rgba(0, 0, 0, 0.3);
  overflow: visible;
}

/* Gateway.Components.Atoms.Tabs.render/1 (3umt3) */

  nav[s-3umt3] {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: fit-content;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  }

/* Gateway.Components.Organisms.Collection.Planning.Mapper (tnvxv) */

.select-wrapper[s-tnvxv] {
  display: grid;
  grid-template-rows: 1fr 16px;
  gap: 4px;
}
.select-wrapper[s-tnvxv] .error[s-tnvxv] {
  @apply text-red-500 text-xs font-semibold;
}

/* Gateway.Components.Organisms.MixPlanningGroups (vv4lf) */

.add-button[s-vv4lf] {
  display: grid;
  place-items: center;

  transition: 0.3s border, 0.3s background, 0.2s opacity;

  border: 2px dashed var(--colorDark15);
}
.add-button[s-vv4lf]:hover {
  background-color: var(--colorDark5);
  border-color: var(--colorDark25);
}
.add-button[s-vv4lf]:focus {
  background-color: var(--colorDark10);
  border-color: var(--colorDark25);
}

/* Gateway.Components.Organisms.Table.Pagination (ak3iq) */

.navigation-button[s-ak3iq] {
  @apply relative inline-flex items-center px-3 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 transition ease-in-out duration-150;
}

/* active not disabled */
.navigation-button[s-ak3iq]:active:not([disabled]) {
  @apply active:bg-gray-100 active:text-gray-500;
}

.navigation-text[s-ak3iq] {
  @apply -ml-px select-none relative inline-flex items-center px-3 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500;
}

.left[s-ak3iq] {
  @apply rounded-l-md;
}

.right[s-ak3iq] {
  @apply -ml-px rounded-r-md;
}

/* Gateway.Pages.Collection.Planning (fxwvo) */

.wrapper[s-fxwvo] {
  padding: 32px;
  display: grid;
  gap: 32px;
}

/* Gateway.Pages.Collection.Planning.Distribution (4y3de) */

.table-header[s-4y3de] {
  @apply px-4 py-2 text-left leading-4 tracking-wider select-none;
  white-space: nowrap;
}
.wrapper[s-4y3de] {
  /* padding: 32px; */
  display: grid;
  gap: 32px;
}

table[s-4y3de] {
  width: stretch;
}
.filter-container[s-4y3de] {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  padding-left: 8px;
}

/* Gateway.Pages.Collection.Planning.Load (lqdfn) */

.table-header[s-lqdfn] {
  @apply px-4 py-2 text-left leading-4 tracking-wider select-none;
  white-space: nowrap;
}
.wrapper[s-lqdfn] {
  padding: 32px;
  display: grid;
  gap: 32px;
}

table[s-lqdfn] {
  width: stretch;
}

/* Gateway.Pages.Dashboards.Stages (gwycx) */

.filter-container {
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 16px;
  display: grid;
}

.data-container[s-gwycx] {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
}

.wrapper[s-gwycx] {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.data-container[s-gwycx] > .item {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex: 2;
  border: 1px solid #d1d1d1;
  border-radius: 8px;
  flex-wrap: wrap;
}

/* Gateway.Pages.Dashboards.Stages.Components.Overview (27t7d) */

.root[s-27t7d] {
  border: 1px solid #d1d1d1;
  border-radius: 8px;
  padding: 24px;
  flex: 1;
}

h3[s-27t7d] {
  display: flex;
  grid-column: 1 / -1;
  font-size: 1.2rem;
  font-weight: 500;
  color: #333;
  user-select: none;
  margin-bottom: 16px;
}

.items-container[s-27t7d] {
  display: flex;
  gap: 4px;
  flex-direction: column;
}

.item[s-27t7d] {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  user-select: none;
}
.item[s-27t7d] > .item-label[s-27t7d] {
  font-weight: 500;
}
.item[s-27t7d] > .value[s-27t7d] {
  justify-self: end;
}

/* Gateway.Pages.Dashboards.Stages.Components.StageCard (ou5i5) */

.root[s-ou5i5] {
  border: 1px solid #d1d1d1;
  border-radius: 8px;
  padding: 24px;
  flex: 1;
}

.responsibles-container[s-ou5i5] {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

h3[s-ou5i5] {
  display: flex;
  grid-column: 1 / -1;
  font-size: 1.2rem;
  font-weight: 500;
  color: #333;
  user-select: none;
  margin-bottom: 16px;
}

/* Gateway.Pages.Illustrator.Product (rqac5) */

.product[s-rqac5] {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: var(--colorDark0);
  border: 1px solid var(--colorDark15);
  border-radius: 6px;
  transition: border-color .1s ease-in-out;
  padding: 0;
  user-select: none;

  border-width: 1px;
  box-shadow: none;

  margin: 2px;

}

.clickable[s-rqac5] {
  cursor: pointer;
}
.clickable[s-rqac5]:hover {
  border-width: 2px;
  margin: 1px;
  border-color: var(--colorBlue50);
}

.header[s-rqac5]{
  position: relative;
  display: block;
  min-height: 40px;
  padding: 10px 10px;
}

.header-content[s-rqac5]{
  display: flex;
  align-items: center;
}

.header-name[s-rqac5]{
  font-family: var(--fontFamilyHeading);
  font-weight: var(--fontWeightMedium);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


.header-code[s-rqac5]{
  margin-left: 0.3em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--colorDark75);
  line-height: 1;
  flex-shrink: 0;
}

.content-image[s-rqac5]{
  display: grid;
  gap: 8px;
  min-height: 150px;
  position: relative;
}

.content-image-wrapper[s-rqac5]{
  margin: auto;
  max-width: 150px;
  max-height: 150px;
  margin: auto;
  display: grid;
}

.image[s-rqac5] {
  position: relative;
  width: 100%;
  height: 100%;
  vertical-align: top;
  background-color: var(--colorDark5);
  border: 1px solid var(--colorDark15);
  border-radius: var(--borderRadius);
  overflow: hidden;
}

.content-items[s-rqac5]{
  width: 100%;
  overflow: hidden;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.fallback-wrapper[s-rqac5]{
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  font-size: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  color: var(--colorDark15);
}

.items[s-rqac5]{
  width: 100%;
  overflow: hidden;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  /* gap: 3px; */
  padding: 6px 8px;
}

.item-label[s-rqac5]{
  flex-shrink: 0;
  color: var(--colorDark75);
  margin-right: 0.5em;
  white-space: nowrap;
  font-size: .88rem;
  display: flex;
  gap: 4px;
  align-items: center;
  align-self: flex-start;
}

.item[s-rqac5]{
  position: relative;
  flex: 1;
  min-width: 0;
  display: flex;
  border-radius: 6px;
  flex-wrap: wrap;
}
.value[s-rqac5]{
  font-size: .88rem;
  white-space: nowrap;
  min-width: 0;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
.value[s-rqac5]:hover{
  white-space: normal;
  text-overflow: clip;
  word-break: break-all;
  min-width: 20%;
}