:root {
  --txt-primary: #fafbff;
  --bg-primary: #455bf3;
  --bg-primary-hover: #3145c9;
  --bg-primary-active: #223195;

  --txt-secondary: #17171b;
  --bg-secondary: white;
  --bg-secondary-hover: #e6e6e6;
  --bg-secondary-active: #d5d5d5;

  --txt-error: #ff3d10;
  --bg-error: #ffeae4;
  --bg-error-hover: #ffd8d0;
  --bg-error-active: #ffbea9;

  --txt-success: white;
  --bg-success: #2fd566;
  --bg-success-hover: #2abf5e;
  --bg-success-active: #1f9e4e;

  --txt-secondary_active: #455bf3;
  --bg-secondary_active: #f3f6fd;
  --bg-secondary_active-hover: #e6eefc;
  --bg-secondary_active-active: #d5e0fb;

  --txt-transparent: #17171b;
  --bg-transparent: transparent;
  --bg-transparent-hover: #e6e6e6;
  --bg-transparent-active: #d5d5d5;

  --colorDark100: #000000;
  --colorDark90: #17171b;
  --colorDark75: #5c5f76;
  --colorDark50: #7a7f8c;
  --colorDark25: #d5d9e4;
  --colorDark15: #e8ebf5;
  --colorDark5: #fafafa;
  --colorDark0: #ffffff;

  --colorWhite: #ffffff;

  --colorGreen10: #deffe9;
  --colorGreen50: #2fd566;
  --colorGreen75: #0b702d;

  --colorOrange10: #fff0cf;
  --colorOrange25: #ffd298;
  --colorOrange50: #ff8f00;

  --colorRed10: #ffeae4;
  --colorRed25: #f97a5f;
  --colorRed50: #ff3d10;
  --colorRed75: #c43414;

  --colorBlue5: #fafbff;
  --colorBlue10: #f3f6fd;
  --colorBlue15: #e9efff;
  --colorBlue25: #bbc7ff;
  --colorBlue50: #455bf3;
  --colorBlue75: #3647c3;

  --colorBase: var(--colorDark100);
  --colorBackground: var(--colorDark5);

  --colorShadow100: #00000000;
  --colorShadow75: #0000003b;
  --colorShadow50: #00000018;
  --colorShadow25: #0000000e;
  --colorShadow0: transparent;
}
