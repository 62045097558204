@import "./_components.css";
@import "./_variables.css";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;1,400;1,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import "../../../../deps/live_monaco_editor/priv/static/live_monaco_editor.min.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .input {
    @apply block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50;
  }
  .checkbox {
    @apply rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50;
  }
}

*::-webkit-scrollbar {
  width: 8px;
}
*::-webkit-scrollbar-thumb {
  background-color: #cccccc;
}

body {
  min-height: 100vh;
  font-style: normal;
  color: black;
  background-color: #fafafa;
  overscroll-behavior-y: none;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 1.5s infinite linear;
}

.input {
  width: 100%;
  display: block;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  background-color: inherit;
  color: currentColor;
  padding: 0.58em 0.62em;
  line-height: 1.25;
  border: 1px solid #d5d9e4;
  border-radius: 6px;
  box-shadow: 0px 0px 4px -1px rgba(black, 0.1);
  transition:
    border 0.3s,
    box-shadow 0.3s;
  outline: none;
  resize: none;
}
.input::placeholder {
  color: #7a7f8c;
}

.input:disabled {
  color: #5c5f76;
  background-color: #fafafa;
}
/* readonly */
.input[readonly] {
  cursor: default;
  user-select: none;
}

.input:focus:not([readonly]) {
  border: 1px solid #7a7f8c;

  box-shadow:
    rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(69 91 243 / 10%) 0px 0px 0px 4px,
    rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(87 60 60 / 0%) 0px 0px 0px 1px,
    rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(0 0 0 / 0%) 0px 0px 0px 0px;
}

.label {
  margin-bottom: 0.5em;
  font-weight: 600;
  color: #17171b;
  -webkit-font-smoothing: antialiased;
}

.paper {
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  background-color: rgb(249 250 251);

  padding: 25px 20px;

  border-radius: 6px;
  box-shadow:
    0 0 0 1px rgba(50, 50, 93, 0.1),
    0 2px 5px 0 rgba(50, 50, 93, 0.08),
    0 1px 1.5px 0 rgba(0, 0, 0, 0.07),
    0 1px 2px 0 rgba(0, 0, 0, 0.08);
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 221.2 83.2% 53.3%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 221.2 83.2% 53.3%;
    --radius: 0.3rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 217.2 91.2% 59.8%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 224.3 76.3% 48%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
  /* Aplicamos essa regra com base na documentação, mas excluimos o botão base legado atualmente usado em varias telas */
  * & :not(base-button) {
    @apply border-border !important;
  }
}

body {
  /* @apply bg-background text-foreground; */
}
